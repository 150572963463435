<template>
  <div class="page workflowEngine">
    <WorkflowEngineCompoonent v-if="configId != null" :configId="configId" />
  </div>
</template>

<script>
import WorkflowEngineCompoonent from '@/components/WorkflowEngineCompoonent.vue';

export default {
  name: 'WorkflowEngineView',
  props: ['configId'],
  components: {
    WorkflowEngineCompoonent,
  },
};

</script>
<style scoped>
.page {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
}
</style>
