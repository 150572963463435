<template>
  <CustomModal v-model="showModal" :showConfirm="$t('general.orderNow')" @confirm="confirm" @cancel="cancel" v-if="subscriptionType != null && subscriptionsById != null">
    <template v-slot:title>{{title}}</template>
    <div class="centered">
      <div class="text">{{$t('invoicing.textConfirmDialog')}}</div>
      <SingleSubscriptionComponent :tenant="tenant" :level="level" :hideButtons="true" :period="periodType" :lang="$t('keyShort')"
        :subscriptions="{ MONTHLY: subscriptionsById[`MONTHLY_${level}`], YEARLY: subscriptionsById[`YEARLY_${level}`] }" @subscribe="subscribe">
      </SingleSubscriptionComponent>
      <div class="hint net_prices_hint">{{$t('invoicing.hint_net_prices')}}</div>
      <div class="hint">{{getHint()}}</div>
      <div class="hint" v-html="$t('invoicing.externalPaymentProviderHint')"/>
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';
import SingleSubscriptionComponent from '@/components/SingleSubscriptionComponent.vue';

export default {
  name: 'ConfirmSubscriptionModal',
  props: ['tenant', 'subscriptionsById'],
  components: {
    CustomModal,
    SingleSubscriptionComponent,
  },
  data() {
    return {
      showModal: false,
      resolve: null,
      reject: null,
      promise: null,
      subscriptionType: null,
      periodType: null,
      level: null,
      title: this.$t('invoicing.titleConfirmDialog'),
    };
  },
  methods: {
    async show(subscriptionType, periodType, level) {
      console.log('SHOW!', subscriptionType, periodType, level);
      this.subscriptionType = subscriptionType;
      this.periodType = periodType;
      this.level = level;
      this.showModal = true;
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
      return this.promise;
    },
    subscribe(subscriptionType, periodType, level) {
      console.log('SUBSCRIBE!', subscriptionType, periodType, level);
      /*
      this.resolve(true);
      this.hide();
      */
    },
    hide() {
      this.showModal = false;
    },
    confirm() {
      this.resolve(true);
      this.hide();
    },
    cancel() {
      this.resolve(false);
      this.hide();
    },
    getHint() {
      const newLevel = this.level;
      const oldLevel = parseInt(this.tenant.subscriptionType.split('_')[1], 10);
      const newPeriod = this.periodType;
      const oldPeriod = this.tenant.subscriptionType.split('_')[0];
      let hint = '';

      if ((newLevel > oldLevel && newPeriod === oldPeriod) || (newPeriod === 'YEARLY' && oldPeriod === 'MONTHLY') || oldPeriod === 'EXPIRED') {
        // upgrade, effective immediately
        hint = this.$t('invoicing.hintEffectiveImmediately');
      } else {
        hint = this.$t('invoicing.hintEffectiveNextPeriod');
      }
      return hint;
    },
  },
};
</script>

<style scoped>
  .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text {
    margin-bottom: 16px;
  }

  .hint {
    margin-top: 16px;
  }

</style>
