<template>
  <div class="subscription-box" v-if="subscriptions != null && periodType != null">
  <div class="subscription-title">
    <slot name="title">{{ subscriptions[periodType].desc[lang] }}</slot>
  </div>
  <div class="subscription-facts">
    <slot name="facts" v-if="subscriptions != null">
      <ul v-html="subscriptions[periodType].facts[lang]"/>
    </slot>
  </div>
  <div class="subscription-price">
    {{periodType == 'MONTHLY'
      ? `EUR ${subscriptions[periodType].price.toLocaleString($t('key'))} / ${$t('general.month')}`
      : `EUR ${subscriptions[periodType].price.toLocaleString($t('key'))} / ${$t('general.year')}` }}
  </div>
  <div class="subscription-period-switch">
    <div class="subscription-period" :class="{active: periodType == 'MONTHLY'}" @click="togglePeriodType">{{$t('general.month')}}</div>
    <div class="subscription-period" :class="{active: periodType == 'YEARLY'}" @click="togglePeriodType">{{$t('general.year')}}</div>
  </div>
  <div class="subscription-footer" v-if="hideButtons !== true">
    <div class="navButton" :class="{disabled: tenant.subscriptionType == `${periodType}_${level}`}" @click="subscribe">{{$t('invoicing.btnSubscribe')}}</div>
  </div>
</div>
</template>
<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

export default {
  name: 'SingleSubscriptionComponent',
  components: {
  },
  props: ['tenant', 'subscriptions', 'level', 'lang', 'hideButtons', 'period'],
  emits: ['subscribe'],
  data() {
    return {
      periodType: 'MONTHLY',
    };
  },
  watch: {
    period(newVal) {
      this.periodType = newVal != null ? newVal : this.periodType;
    },
  },
  mounted() {
    console.log('subscriptions', this.subscriptions);
    this.periodType = this.period != null ? this.period : this.periodType;
  },
  methods: {
    togglePeriodType() {
      this.periodType = this.periodType === 'MONTHLY' ? 'YEARLY' : 'MONTHLY';
    },
    subscribe() {
      if (`${this.periodType}_${this.level}` !== this.tenant.subscriptionType) {
        console.log('SEND SUBSCRIBE', `${this.periodType}_${this.level}`, this.periodType, this.level);
        this.$emit('subscribe', `${this.periodType}_${this.level}`, this.periodType, this.level);
      }
    },
  },
};
</script>
<style scoped>

.subscription-box {
  flex: 1 1 auto ;
  max-width: 300px;
  min-width: 180px;
  border: 1px solid var(--color_mint);
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  transition: all 0.2s ease-in-out;
}

.subscription-box:hover {
  background-color: var(--color_mint_half);
  box-shadow: 1px 1px 8px var(--color_mint);
}

.subscription-title {
  flex: 0 1 auto ;
  text-align: center;
  font-size: 1.5em;
  font-weight: 600;
}

.subscription-period-switch {
  background-color: var(--color_brown);
  flex: 0 1 auto ;
  position: relative;
  padding: 4px;
  line-height: 24px;
  border-radius: 16px;
}

.subscription-period {
  line-height: 24px;
  width: 49.9%;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  color: white;
}

.subscription-period.active {
  background-color: white;
  color: var(--color_brown);
  border-radius: 12px;
  font-weight: bold;
}

.subscription-facts {
  flex: 1 1 auto ;
  min-height: 175px;
}

.subscription-facts ul {
  margin: 0;
  padding: 0px 20px;
}

.subscription-price {
  margin-top: 32px;
  flex: 0 1 auto ;
  text-align: center;
  font-weight: 500;
  font-size: 1.2em;
}

.subscription-footer {
  flex: 0 1 auto ;
  text-align: center;
}

.subscription-footer .navButton {
  margin-top: 16px;
  width: calc(100% - 24px);
}

.subscription-footer .navButton.disabled {
  background-color: var(--color_orange_half);
}

.subscription-footer .navButton.disabled:hover {
  background-color: var(--color_orange_half);
}

</style>
