<template>
  <div class="workflowEngine">
    <div class="wfe-container">
      <WorkflowToolbarComponent class="wfe-toolbar" @back="$router.back()" @execute="execute" @save="saveWorkflow" @load="loadWorkflow"/>
      <div class="wfe-editor-data-container">
        <div class="wfe-editor" id="wf-engine-editor" ref="wf-engine-editor"/>
        <div class="wfe-testdata">
          <ReportListComponent v-if="configId != null" :reportConfigId="configId" :reportConfigName="configName" :minimized="true" @reportSelected="reportSelected"/>
          <!--<pre v-if="testData != null" v-html="JSON.stringify(testData.reportData, null, 2)"/>-->
        </div>
      </div>
    </div>
    <ConfirmWorkflowExecutionModal ref="confirmExecution" :title="$t('modal.titleConfirmExecution')" :text="$t('modal.textConfirmExecution')"/>
  </div>
</template>
<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle,no-bitwise */
import ReportListComponent from '@/components/ReportListComponent.vue';
import WorkflowToolbarComponent from '@/components/WorkflowToolbarComponent.vue';
import ConfirmWorkflowExecutionModal from '@/components/modals/ConfirmWorkflowExecutionModal.vue';
import CryptoHelper from '@/helpers/CryptoHelper';
import ServiceHelper from '@/helpers/ServiceHelper';
import CustomWorkflowNodes from '@/helpers/customWorkflowNodes';

import { NodeEditor, DefaultOptions } from '@/helpers/node-editor-ui.node';

export default {
  name: 'WorkflowEngineComponent',
  props: ['configId'],
  components: {
    ReportListComponent,
    ConfirmWorkflowExecutionModal,
    WorkflowToolbarComponent,
  },
  data() {
    return {
      testData: { reportConfig: {}, reportData: { state: 0, assigned_values: [] } },
      loadedWorkflowId: null,
      loadedWorkflowName: null,
    };
  },
  mounted() {
    document.addEventListener('contextmenu', this.contextMenuListener);
    this.$nextTick(() => this.initEditor());
  },
  unmounted() {
    document.removeEventListener('contextmenu', this.contextMenuListener);
  },
  methods: {
    initEditor() {
      const editorTexts = this.$t('keyShort') === 'de' ? DefaultOptions.availableLanguages.lang_DE : DefaultOptions.availableLanguages.lang_EN;
      // add translated keys to choose
      editorTexts.nodes = {
        ...editorTexts.nodes,
        FieldChooser: { labelInChooser: this.$t('workflow.nodeChooser.FieldChooser') },
        GetFieldValue: { labelInChooser: this.$t('workflow.nodeChooser.GetFieldValue') },
        GetFieldValueCustom: { labelInChooser: this.$t('workflow.nodeChooser.GetFieldValueCustom') },
        SetFieldValue: { labelInChooser: this.$t('workflow.nodeChooser.SetFieldValue') },
        SetFieldValueCustom: { labelInChooser: this.$t('workflow.nodeChooser.SetFieldValueCustom') },
        GetCreationDate: { labelInChooser: this.$t('workflow.nodeChooser.GetCreationDate') },
        GetModificationDate: { labelInChooser: this.$t('workflow.nodeChooser.GetModificationDate') },
        GetStatus: { labelInChooser: this.$t('workflow.nodeChooser.GetStatus') },
        SetStatus: { labelInChooser: this.$t('workflow.nodeChooser.SetStatus') },
        StatusConstant: { labelInChooser: this.$t('workflow.nodeChooser.StatusConstant') },
        SendMail: { labelInChooser: this.$t('workflow.nodeChooser.SendMail') },
      };

      this.nodeEditor = new NodeEditor('wf-engine-editor', { lang: editorTexts });
      this.nodeEditor.nodeCatalog = { ...{ Collada: CustomWorkflowNodes }, ...this.nodeEditor.nodeCatalog };
      this.nodeEditor.contextData = this.testData;
      this.nodeEditor.translate = (key) => this.$t(`workflow.node.${key}`);

      this.loadConfig();
    },
    processReport(report, graphConfig) {
      return new Promise((resolve, reject) => {
        try {
          ServiceHelper.loadDataFromBackend(`report/${report._id}`).then((detailData) => {
            const content = JSON.parse(CryptoHelper.decrypt(detailData.payload.content));
            const nodeEditor = new NodeEditor();
            nodeEditor.nodeCatalog = { ...nodeEditor.nodeCatalog, ...{ Collada: CustomWorkflowNodes } };
            nodeEditor.contextData = {
              reportConfig: this.loadedConfig,
              reportData: {
                state: detailData.payload.state ?? 0,
                ...content,
              },
            };
            console.log(nodeEditor.contextData);
            nodeEditor.fromJSON(graphConfig);
            nodeEditor.runOnce(async (tasks) => {
              resolve(tasks);
            });
          }).catch((err) => {
            reject(err);
          });
        } catch (e) {
          console.error(e);
          reject(e);
        }
      });
    },

    async processAllReports() {
      const results = [];
      try {
        const data = await ServiceHelper.loadDataFromBackend(`reportNames/${this.configId}`, { page: 0, limit: Number.MAX_SAFE_INTEGER, sorting: 'createdAsc' });
        const { reports } = data.payload;
        const graphConfig = this.nodeEditor.getJSON();
        for (let i = 0; i < reports.length; i += 1) {
          const executionData = {
            report: reports[i],
            tasks: [],
            success: true,
          };
          results.push(executionData);
          console.log(`processing report ${i + 1} / ${reports.length} `);
          try {
            // eslint-disable-next-line no-await-in-loop
            executionData.tasks = await this.processReport(reports[i], graphConfig);
          } catch (err) {
            executionData.success = false;
            executionData.error = err;
          }
        }
      } catch (err) {
        console.error(err);
      }
      return results;
    },

    /* eslint-disable */
    async execute() {

      // asynchronously fetch all reports one by one and perform the workflow headless on it.
      const results = await this.processAllReports();
      console.log('RESULTS', results);
      let tasksToBeExecuted = await this.$refs.confirmExecution.show(results.filter((r) => r.tasks.length > 0));
      if (tasksToBeExecuted != null) {
        tasksToBeExecuted = tasksToBeExecuted.filter((r) => r.active);
        for (const reportResult of tasksToBeExecuted) {
          const tasks = reportResult.tasks.filter((t) => t.active)
          for (let task of tasks) {
            try {
              const detailData = await ServiceHelper.loadDataFromBackend(`report/${reportResult.report._id}`)
              const content = JSON.parse(CryptoHelper.decrypt(detailData.payload.content));
              const state = detailData.payload.state
              const id = detailData.payload._id

              if (task.type === 'set_value') {
                await this.performSetValueTask(task, content, state, id);
              } else if (task.type === 'send_email') {
                await this.performSendMailTask(task, state, id);
              } else if (task.type === 'set_status') {
                await this.performSetStatus(task, content, state, id);
              }
            } catch (e) {
              console.error(e)
            }
          }
        }
      }
    },
    async performSetValueTask(task, reportData, state, id) {
      if (task.oldValue != null) {
        console.log('UPDATE', task);
        for (let v of reportData.assigned_values) {
          if (v.valueKey == task.valueKey) {
            v.value = task.newValue;
            break;
          }
        }
      } else {
        console.log('NEW VAL', task);
        reportData.assigned_values.push({
          valueKey: task.valueKey,
          value: task.newValue,
        });
      }

      try {
        await ServiceHelper.sendDataToBackend(`report/${id}/content`, { content: CryptoHelper.encrypt(JSON.stringify(reportData)) }, 'PUT');
      } catch(e) {
        window.alert("Setting value failed!")
        console.error(e);
      }

    },
    async performSendMailTask(task, reportData, state, id) {
      let settings = null
      let tenant = this.$store.getters.tenant
      if (tenant != null && tenant.mailSettings != null) {
        try {
          const dec = CryptoHelper.decrypt(tenant.mailSettings);
          settings = JSON.parse(dec);
        } catch (e) {
          console.error(e);
        }
      }
      if (settings) {
        let config = {
          to: task.to,
          subject: task.subject,
          body: task.body,
          ...settings,
        }
        try {
          await ServiceHelper.sendDataToBackend('mail', config)
        } catch(e) {
          window.alert("Sending mail failed!")
          console.error(e);
        }
      } else {
        window.alert("There's a problem with your mail settings.")
      }
    },
    async performSetStatus(task, reportData, state, id) {
      reportData.state = task.newValue;
      await ServiceHelper.sendDataToBackend(`report/${id}/state`, { state: task.newValue });
    },
    contextMenuListener(event) {
      event.preventDefault();
    },
    resizeListener() {
      if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.nodeEditor.resize();
      })
    },
    async saveWorkflow() {
      const wfName = 'default'; // window.prompt('Name', this.loadedWorkflowName ?? '');
      if (wfName) {
        this.loadedWorkflowName = wfName;
        const name = CryptoHelper.encrypt(wfName);
        const content = CryptoHelper.encrypt(this.nodeEditor.getJSON());
        if (this.loadedWorkflowId) {
          // update
          await ServiceHelper.sendDataToBackend(`workflow/${this.loadedWorkflowId}`, {
            name,
            content,
          }, 'PUT');
        } else {
          // new
          await ServiceHelper.sendDataToBackend(`workflow/${this.configId}`, {
            name,
            content,
          });
        }
      }
    },
    async loadWorkflow() {
      const data = await ServiceHelper.loadDataFromBackend(`workflows/${this.configId}`);
      if (data && data.payload.length > 0) {
        const workflows = data.payload;
        const workflow = workflows[0];
        this.loadedWorkflowId = workflow._id;
        this.loadedWorkflowName = CryptoHelper.decrypt(workflow.name);
        const dec = CryptoHelper.decrypt(workflow.content);
        this.nodeEditor.fromJSON(dec);
        console.log('Workflow loaded', this.nodeEditor.canvas, this.nodeEditor.context);
      }
    },
    async reportSelected(report) {
      const detailData = await ServiceHelper.loadDataFromBackend(`report/${report._id}`);

      this.nodeEditor.contextData.reportData = {
        state: report.state,
        ...JSON.parse(CryptoHelper.decrypt(detailData.payload.content))
      };
      this.nodeEditor.evaluate();
    },
    async loadConfig() {
      try {
        const data = await ServiceHelper.loadDataFromBackend(`reportConfig/${this.configId}`);
        this.backendOperation = null;
        // check for error response
        const decrypted = CryptoHelper.decrypt(data.payload.content);
        this.loadedConfig = JSON.parse(decrypted);
        this.nodeEditor.contextData.reportConfig = this.loadedConfig;
        console.log('Config loaded', this.loadConfig);
        this.loadWorkflow();
      } catch (e) {
        console.error(e);
      }
      return null;
    },
  },
};
</script>

<style scoped>
  .workflowEngine {
    height: 100%;
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .wfe-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .wfe-editor-data-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
  }

  .wfe-editor {
    flex: 1 1 auto;
    overflow: hidden;
  }

  .wfe-testdata {
    flex: 0 1 400px;
    overflow-y: auto;
    border-left: 1px solid black;
    padding-left: 8px;
    background-color: #f4f4f4;
    color: black;
  }

  .wfe-toolbar {
    flex: 0 1 auto;
  }

  ::v-deep(div.neui_drop_down_chooser) {
    background-color: #eeeeff;
    border: 4px solid #888888;
    border-radius: 5px;
    padding: 8px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    overflow-y: auto;
  }

  ::v-deep(div.neui_drop_down_chooser.visible) {
    opacity: 1;
  }

  ::v-deep(div.neui_drop_down_chooser div.option) {
    cursor: pointer;
    background-color: #ffffff;
    color: #888888;
    padding: 4px;
    margin-bottom: 4px;
    border: 1px solid #888888;
    transition: all 0.2s ease-in-out;
  }

  ::v-deep(div.neui_drop_down_chooser div.option:hover) {
    background-color: #ffaa66;
    color: white
  }

  ::v-deep(div.listItemWrapper div.listItem) {
    width: 100% !important
  }

  ::v-deep(div.paginator) {
    padding-bottom: 20px !important;
  }

  ::v-deep(div.paginator div) {
    font-size: 10px !important;
    line-height: 12px !important;
  }

  ::v-deep(div.neui_drop_down_chooser div.option .highlighted) {
    color: black;
    font-weight: bold;
  }

  ::v-deep(div.neui_drop_down_chooser .searchfieldwrapper) {
    width: 100%;
    margin: 4px 0px;
    display: flex;
  }

  ::v-deep(div.neui_drop_down_chooser .searchfield) {
    flex: 1 1 auto;
    padding: 4px;
  }


  ::v-deep(div.neui_drop_down_chooser div.category) {
    color: #888888;
    padding: 4px;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 800;
    margin-top: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    position: relative;
  }

  ::v-deep(div.neui_drop_down_chooser div.category:hover) {
    color: #ffaa66;
  }

  ::v-deep(div.neui_drop_down_chooser div.category.expanded::after) {
    position: absolute;
    margin-left: 8px;
    content: "▲";
    color: #888888;
  }

  ::v-deep(div.neui_drop_down_chooser div.category.collapsed::after) {
    position: absolute;
    margin-left: 8px;
    content: "▼";
    color: #888888;
  }


</style>
