/* eslint-disable */
import { Node, OutPort, InPort, DropDownChooser} from './node-editor-ui.node';
const Constants = require('./Constants');
const { parse } = require('date-fns');

let fieldsToSkip = [Constants.FIELD_TYPES.label, Constants.FIELD_TYPES.action, Constants.FIELD_TYPES.image];

class CustomNode extends Node {
  getDynamicTitle = () =>  {
    if (this.getEditorReference().translate) {
      return this.getEditorReference().translate(`${this.constructor.name}`)
    }
    return super.getDynamicTitle()
  }
}

class FieldChooser extends CustomNode {
  constructor(context, getEditorReference, title, x = 0, y = 0, w, h, props) {
    super(context, getEditorReference, "Field name", x, y, w > -1 ? w : 120, h > -1 ? h : 100, props);
    this.props = { options: this.getEditorReference().contextData.reportConfig.fields.filter((f) => !fieldsToSkip.includes(f.type))}
    this.inPorts = []
    this.outPorts = [new OutPort(this, "String", "Chosen Field")]
    this.field = props?.field ?? null
    this.props.field = this.field
    if (!this.getEditorReference().headless && document) {
      this.dropDownChooser = new DropDownChooser(this.getEditorReference().elem,
        this.props.options,
        this.getEditorReference().options.lang,
        // callback when mouse has left chooser div
        () => {
          this.dropDownChooser.hide();
        },
        // callback when a class has been chosen
        (option, x, y) => {
          this.field = option
          this.props.field = option
          this.dropDownChooser.hide();
          this.titleNeedsRefresh = true
          this.getEditorReference().evaluate();
        }
      )
      this.dropDownChooser.getOptionTitle = function (option) {
        return option.title.de;
      }
    }
  }

  static acceptedInputTypes() { return [] }
  static outputType() { return "String" }

  evaluate() {
    this.currentValue = null;
    if (this.field != null) {
      this.currentValue = this.field.name
    }
    super.evaluate();
  }

  doubleClicked(evt) {
    this.dropDownChooser.show(evt.offsetX,evt.offsetY,0,0)
  }

  closeOverlays() {
    this.dropDownChooser.hide();
  }

  getCurrentValueString() {
    if (this.currentValue != null) {
      return this.field.title.de;
    }
    return super.getCurrentValueString()
  }
}


class GetFieldValue extends CustomNode {
  constructor(context, getEditorReference, title, x = 0, y = 0, w = 120, h = 100, props) {
    super(context, getEditorReference, "Field value", x, y, w, h, props);
    this.props = { options: this.getEditorReference().contextData.reportConfig.fields.filter((f) => !fieldsToSkip.includes(f.type))}
    this.inPorts = []
    this.outPorts = [new OutPort(this, "String", "Value")]
    this.field = props?.field ?? null
    this.props.field = this.field
    if (!this.getEditorReference().headless && document) {
      this.dropDownChooser = new DropDownChooser(this.getEditorReference().elem,
        this.props.options,
        this.getEditorReference().options.lang,
        // callback when mouse has left chooser div
        () => {
          this.dropDownChooser.hide();
        },
        // callback when a class has been chosen
        (option, x, y) => {
          this.field = option
          this.props.field = option
          this.dropDownChooser.hide();
          this.titleNeedsRefresh = true
          this.getEditorReference().evaluate();
        }
      )
      this.dropDownChooser.getOptionTitle = function (option) {
        return option.title.de;
      }
    }
  }

  static acceptedInputTypes() { return [] }
  static outputType() { return "String" }

  evaluate() {
    this.currentValue = null;
    if (this.field != null) {
      let data = this.getEditorReference().contextData;
      for (let v of data.reportData.assigned_values) {
        if (v.valueKey == this.field.name) {
          this.currentValue = v.value
          break
        }
      }
      super.evaluate();
    }
  }

  doubleClicked(evt) {
    this.dropDownChooser.show(evt.offsetX,evt.offsetY,0,0)
  }

  closeOverlays() {
    this.dropDownChooser.hide();
  }
}

class SetFieldValue extends CustomNode {
  constructor(context, getEditorReference, title, x = 0, y = 0, w = 120, h = 100, props) {
    super(context, getEditorReference, "Field value", x, y, w, h, props ? props : {value: 0, static: true});
    this.props = { options: this.getEditorReference().contextData.reportConfig.fields.filter((f) => !fieldsToSkip.includes(f.type))}
    this.field = props?.field ?? null
    this.props.field = this.field
    this.inPorts = [new InPort(this, "Boolean", "Execute?"), new InPort(this, "String", "Value to set")]
    this.outPorts = []

    if (!this.getEditorReference().headless && document) {
        this.dropDownChooser = new DropDownChooser(this.getEditorReference().elem,
        this.props.options,
        this.getEditorReference().options.lang,
        // callback when mouse has left chooser div
        () => {
          this.dropDownChooser.hide();
        },
        // callback when a class has been chosen
        (option, x, y) => {
          this.field = option
          this.dropDownChooser.hide();
          this.props.field = option
          this.titleNeedsRefresh = true
          this.getEditorReference().evaluate();
        }
      )
      this.dropDownChooser.getOptionTitle = function (option) {
        return option.title.de;
      }
    }
  }

  static acceptedInputTypes() { return ["Boolean", "String"] }
  static outputType() { return null }

  evaluate() {
    let input = this.inPorts[1].getInputValue()
    this.currentValue = input

    // TODO: CHECK FIELD TYPE MATCHES INPUT!!??
  }

  addAction(old) {
    if (old == null || old.value != this.currentValue) {
      if (this.getEditorReference().executionTasks == null) this.getEditorReference().executionTasks = []
      // TODO: CHECK FIELD TYPE MATCHES INPUT!!??
      this.getEditorReference().executionTasks.push({
        taskName: old?.value == null
          ? `Set Value of field <strong>${this.field.title.de}</strong> to <strong>${this.currentValue}</strong>`
          : `Set Value of field <strong>${this.field.title.de}</strong> from <strong>${old.value}</strong> to <strong>${this.currentValue}</strong>`,
        type: 'set_value',
        field: this.field.title,
        valueKey: this.field.name,
        oldValue: old?.value ?? null,
        newValue: this.currentValue
      })
    }
  }

  execute() {
    let doWrite = this.inPorts[0].getInputValue()
    if (doWrite && this.field != null) {
      let data = this.getEditorReference().contextData;
      let oldValueFound = false
      for (let v of data.reportData.assigned_values) {
        if (v.valueKey == this.field.name) {
          this.addAction(v)
          oldValueFound = true;
          break;
        }
      }
      if (!oldValueFound) {
        this.addAction(null)
      }
      this.getEditorReference().evaluate()
      super.execute();
    }
  }

  doubleClicked(evt) {
    this.dropDownChooser.show(evt.offsetX,evt.offsetY,0,0)
  }

  closeOverlays() {
    this.dropDownChooser.hide();
  }
}


class SetFieldValueCustom extends CustomNode {
  constructor(context, getEditorReference, title, x = 0, y = 0, w, h, props) {
    super(context, getEditorReference, "Set field", x, y, w > -1 ? w : 120, h > -1 ? h : 140, props ? props : {value: 0, static: true});
    this.props = { options: this.getEditorReference().contextData.reportConfig.fields.filter((f) => !fieldsToSkip.includes(f.type))}
    this.field = props?.field ?? null
    this.props.field = this.field
    this.inPorts = [new InPort(this, "Boolean", "Execute?"), new InPort(this, "String", "Fieldname"), new InPort(this, "String", "Value to set")]
    this.outPorts = []
  }

  static acceptedInputTypes() { return ["Boolean", "String"] }
  static outputType() { return null }

  evaluate() {
    this.fieldName = this.inPorts[1].getInputValue()
    this.fieldValue = this.inPorts[2].getInputValue()
    this.currentValue = this.fieldValue

    // TODO: CHECK FIELD TYPE MATCHES INPUT!!??
  }

  addAction(old) {
    console.log('custom field', old, this.fieldName, this.fieldValue, this.currentValue)
    if (old == null || old.value != this.currentValue) {
      if (this.getEditorReference().executionTasks == null) this.getEditorReference().executionTasks = []
      // TODO: CHECK FIELD TYPE MATCHES INPUT!!??
      this.getEditorReference().executionTasks.push({
        taskName: old?.value == null
          ? `Set Value of field <strong>${this.fieldName}</strong> to <strong>${this.currentValue}</strong>`
          : `Set Value of field <strong>${this.fieldName}</strong> from <strong>${old.value}</strong> to <strong>${this.currentValue}</strong>`,
        type: 'set_value',
        field: this.fieldName,
        valueKey: this.fieldName,
        oldValue: old?.value ?? null,
        newValue: this.currentValue
      })
    }
  }

  execute() {
    let doWrite = this.inPorts[0].getInputValue()
    if (doWrite && this.fieldName != null) {
      let data = this.getEditorReference().contextData;
      let oldValueFound = false
      for (let v of data.reportData.assigned_values) {
        if (v.valueKey == this.fieldName) {
          this.addAction(v)
          oldValueFound = true;
          break;
        }
      }
      if (!oldValueFound) {
        this.addAction(null)
      }
      this.getEditorReference().evaluate()
      super.execute();
    }
  }

}


class GetFieldValueCustom extends CustomNode {
  constructor(context, getEditorReference, title, x = 0, y = 0, w, h, props) {
    super(context, getEditorReference, "Get field", x, y, w > -1 ? w : 120, h > -1 ? h : 140, props ? props : {value: 0, static: true});
    this.props = { options: this.getEditorReference().contextData.reportConfig.fields.filter((f) => !fieldsToSkip.includes(f.type))}
    this.field = props?.field ?? null
    this.props.field = this.field
    this.inPorts =  [new InPort(this, "String", "Fieldname")]
    this.outPorts = [new OutPort(this, "String", "Value")]
  }

  static acceptedInputTypes() { return ["Boolean", "String"] }
  static outputType() { return "String" }

  evaluate() {
    this.currentValue = null;
    this.fieldName = this.inPorts[0].getInputValue()
    let data = this.getEditorReference().contextData;
    for (let v of data.reportData.assigned_values) {
      if (v.valueKey == this.fieldName) {
        this.currentValue = v.value
        break
      }
    }
    // console.log('GetFieldValueCustom EVALUATE', data.reportData.assigned_values, this.currentValue, this.fieldName )
    super.evaluate();
  }

}


class GetCreationDate extends CustomNode {
  constructor(context, getEditorReference, title, x = 0, y = 0, w = 120, h = 100, props = {}) {
    super(context, getEditorReference, "Creation Date", x, y, w, h, props);
    this.inPorts = []
    this.outPorts = [new OutPort(this, "Date")]
  }

  static acceptedInputTypes() { return [] }
  static outputType() { return "Date" }

  evaluate() {
    this.currentValue = null;
    let data = this.getEditorReference().contextData;
    if (data != null && data.reportData.timeOfCreation != null) {
      this.currentValue = parse(data.reportData.timeOfCreation, 'yyyy-MM-dd HH:mm:ss', new Date())
    }
    this.titleNeedsRefresh = true;
    super.evaluate();
  }
}


class GetModificationDate extends CustomNode {
  constructor(context, getEditorReference, title, x = 0, y = 0, w = 120, h = 100, props = {}) {
    super(context, getEditorReference, "Modified Date", x, y, w, h, props);
    this.inPorts = []
    this.outPorts = [new OutPort(this, "Date")]
  }

  static acceptedInputTypes() { return [] }
  static outputType() { return "Date" }

  evaluate() {
    this.currentValue = null;
    let data = this.getEditorReference().contextData;
    if (data != null && data.reportData.timeOfModification != null) {
      this.currentValue = parse(data.reportData.timeOfModification, 'yyyy-MM-dd HH:mm:ss', new Date())
    }
    this.titleNeedsRefresh = true;
    super.evaluate();
  }

}


class GetStatus extends CustomNode {
  constructor(context, getEditorReference, title, x = 0, y = 0, w = 120, h = 100, props = {}) {
    super(context, getEditorReference, "Get Status", x, y, w, h, props);
    this.inPorts = []
    this.outPorts = [new OutPort(this, "Number")]
  }

  static acceptedInputTypes() { return [] }
  static outputType() { return "Number" }

  evaluate() {
    let data = this.getEditorReference().contextData;
    this.currentValue = data.reportData.state
    this.titleNeedsRefresh = true;
    super.evaluate();
  }


  getCurrentValueString() {
    if (this.currentValue != null) {
      let val = `${this.currentValue}`
      switch(this.currentValue) {
        case 0: val = "neu"; break;
        case 1: val = "in Bearbeitung"; break;
        case 2: val = "erledigt"; break;
      }
      return val
    }
    return super.getCurrentValueString()
  }
}


class SetStatus extends CustomNode {
  constructor(context, getEditorReference, title, x = 0, y = 0, w = 120, h = 100, props = {}) {
    super(context, getEditorReference, "Set Status", x, y, w, h, props);
    this.inPorts = [new InPort(this, "Boolean"), new InPort(this, "Number")]
    this.outPorts = []
  }

  static acceptedInputTypes() { return ["Boolean", "Number"] }
  static outputType() { return null }

  evaluate() {
    let input = this.inPorts[1].getInputValue();
    this.currentValue = input
    this.titleNeedsRefresh = true;
    super.evaluate();
  }

  addAction() {
    let data = this.getEditorReference().contextData.reportData;
    if (data.state != this.currentValue) {
      if (this.getEditorReference().executionTasks == null) this.getEditorReference().executionTasks = []
      // TODO: CHECK FIELD TYPE MATCHES INPUT!!??
      this.getEditorReference().executionTasks.push({
        taskName: `Set status from <strong>${data.state ?? 0}</strong> to <strong>${this.currentValue}</strong>`,
        type: 'set_status',
        oldValue: data.state ?? 0,
        newValue: this.currentValue
      })
    }
  }

  execute() {
    let doWrite = this.inPorts[0].getInputValue()
    if (doWrite && this.currentValue != null) {
      this.addAction()
      // let data = this.getEditorReference().contextData;
      // data.reportData.state = this.currentValue
      // this.titleNeedsRefresh = true
      // this.getEditorReference().evaluate();
    }
  }


  getCurrentValueString() {
    if (this.currentValue != null) {
      let val = `${this.currentValue}`
      switch(this.currentValue) {
        case 0: val = "neu"; break;
        case 1: val = "in Bearbeitung"; break;
        case 2: val = "erledigt"; break;
      }
      return val
    }
    return super.getCurrentValueString()
  }

}


class StatusConstant extends CustomNode {
  constructor(context, getEditorReference, title, x = 0, y = 0, w = 120, h = 100, props) {
    super(context, getEditorReference, "Status", x, y, w, h, props ? props : {value: {title: "neu", value:  0}}) ;
    this.inPorts = []
    this.outPorts = [new OutPort(this, "Number")]
    this.currentValue = this.props.value

    if (!this.getEditorReference().headless && document) {
        this.dropDownChooser = new DropDownChooser(this.getEditorReference().elem,
        [{title: "neu", value: 0}, {title: "in Bearbeitung", value: 1}, {title: "erledigt", value: 2}],
        this.getEditorReference().options.lang,
        // callback when mouse has left chooser div
        () => {
          this.dropDownChooser.hide();
        },
        // callback when a class has been chosen
        (option, x, y) => {
          this.currentValue = option
          this.dropDownChooser.hide();
          this.titleNeedsRefresh = true
          this.getEditorReference().evaluate();
        }
      )
      this.dropDownChooser.getOptionTitle = function (option) {
        return option.title;
      }
    }
  }

  static acceptedInputTypes() { return [] }
  static outputType() { return "Number" }

  getOutputForPort(outPort) {
    return this.currentValue.value;
  }

  doubleClicked(evt) {
    this.dropDownChooser.show(evt.offsetX,evt.offsetY,0,0)
  }

  getCurrentValueString() {
    if (this.currentValue != null) {
      return this.currentValue.title;
    }
    return super.getCurrentValueString()
  }

  closeOverlays() {
    this.dropDownChooser.hide();
  }

}

class SendMail extends CustomNode {
  constructor(context, getEditorReference, title, x = 0, y = 0, w, h, props = {}) {
    super(context, getEditorReference, "Send Mail", x, y, w > -1 ? w : 120, h > -1 ? h : 200, props);
    this.inPorts = [new InPort(this, "Boolean", "Activate"), new InPort(this, "String", "To E-Mail"), new InPort(this, "String", "Subject"), new InPort(this, "String", "Text")]
    this.outPorts = []
  }

  evaluate() {
    this.active = this.inPorts[0].getInputValue();
    this.to = this.inPorts[1].getInputValue();
    this.subject = this.inPorts[2].getInputValue();
    this.body = this.inPorts[3].getInputValue();
    super.evaluate();
  }

  static outputType() { return null }

  addAction() {
    if (this.getEditorReference().executionTasks == null) this.getEditorReference().executionTasks = []
    this.getEditorReference().executionTasks.push({
      taskName: `Send E-Mail with subject &quot;<strong>${this.subject}</strong>&quot;<br/>to <strong>${this.to}</strong>`,
      type: 'send_email',
      to: this.to,
      subject: this.subject,
      body: this.body,
    })
  }

  execute(executionId) {
    this.evaluate();
    console.log("NODE SEND MAIL")
    if (this.active == true && this.to != null && this.subject != null && this.body != null) {
      console.log("NODE SEND MAIL ADD ACTION")
      this.addAction()
    }
    super.execute(executionId);
  }


}

export default {
  FieldChooser,
  GetFieldValue,
  GetFieldValueCustom,
  SetFieldValue,
  SetFieldValueCustom,
  GetStatus,
  SetStatus,
  StatusConstant,
  SendMail,
  GetCreationDate,
  GetModificationDate
}
