<template>
  <div class="page invoicing">
    <div class="page-title">{{ $t('pageTitle.invoicing') }}
      <div class="actionBar">
        <div class="actionButton bgBrown refresh" @click="refresh"><i class="fas fa-sync"></i>
          <div class='actionButtonTitle'>{{ $t('general.refresh') }}</div>
        </div>
      </div>
    </div>
    <div class="topNav">
      <router-link to="/settings"><div class='navButton'><i class="fas fa-arrow-left"/> {{ $t('general.back') }}</div></router-link>
    </div>
    <div class="page-content" v-if="tenant != null && subscriptionsById != null">

      <div v-if="(tenant.emailConfirmed == false || accountHolder == null)" class="tenant-info email-hint">
        <div v-if="tenant.emailConfirmed == false && accountHolder != null">{{$t('invoicing.email_unconfirmed_hint')}}</div>
        <div v-if="accountHolder == null">{{$t('invoicing.complete_accountholder')}}</div>
      </div>

      <div class="tenant-info subscription-status" v-if="tenant.subscriptionType == 'FREE'">
        <strong>Status:</strong> <span>{{subscriptionsById.FREE.desc[$t('keyShort')]}}&nbsp;</span>
      </div>

      <div class="tenant-info subscription-status" v-if="tenant.subscriptionType != 'FREE'">
        {{subscriptionsById[tenant.subscriptionType].desc[$t('keyShort')]}},&nbsp;
        <div v-if="tenant.willRenew" class="actionButton bgBrown" @click="cancelSubscription">{{$t('invoicing.cancelSubscription')}} <i class="fas fa-ban"/></div>
        <div v-else-if="canReactivate" class="actionButton bgBrown" @click="reactivateSubscription">{{$t('invoicing.reactivate')}} <i class="fas fa-shopping-cart"/></div>
        <strong>Status:</strong>&nbsp;
        <span class="running" v-if="tenant.willRenew"><i class='fas fa-check'/> {{$t('invoicing.running')}}</span>
        <span class="subscription-status cancelled" v-else-if="tenant != null"><i class='fas fa-exclamation-circle'/> {{$t('invoicing.cancelled')}}</span><br/>

        <span v-if="tenant.subscriptionExpiryDate != null">{{$t('invoicing.endOfCurrentPeriod')}}: {{new Date(tenant.subscriptionExpiryDate).toLocaleString()}}</span><br/>
        <span v-if="tenant.willRenew">
          {{$t('invoicing.nextProductId')}}: {{subscriptionsById[tenant.nextSubscriptionType != null ? tenant.nextSubscriptionType : tenant.subscriptionType].desc[$t('keyShort')]}}
        </span>
      </div>

      <div v-if="subscriptions != null && tenant.accountHolder != null && tenant.emailConfirmed && tenant.subscriptionType == 'EXPIRED' || tenant.subscriptionType == 'FREE' || showSubscriptions" >
        <h3>{{$t('invoicing.subscriptions')}}:</h3>
        <SubscriptionsComponent class="tenant-info" :tenant="tenant" :subscriptionsById="subscriptionsById"
          @subscribe="createPayment"/>
      </div>

      <div v-if="false && tenant != null" class="tenant-info">
        <h3>{{$t('invoicing.tenant')}}:</h3>
        ID: {{tenant.tenantId}}<br/>
        Name: {{tenant.tenantName}}<br/>
        {{$t('invoicing.subscriptionType')}}: {{tenant.subscriptionType}} | {{tenant.willRenew ? $t('invoicing.running') : $t('invoicing.cancelled')}}<br/>
        {{$t('invoicing.subscriptionPeriodStart')}}: {{tenant.subscriptionStartDate != null ? new Date(tenant.subscriptionStartDate).toLocaleDateString() : ''}}<br/>
        {{$t('invoicing.subscriptionPeriodEnd')}} {{tenant.subscriptionExpiryDate != null ? new Date(tenant.subscriptionExpiryDate).toLocaleDateString() : ''}}<br/>
      </div>
      <div class="tenant-info" v-if="accountHolder != null">
        <div class="actionButton bgBrown" @click="showAccountHolderModel">{{$t('invoicing.edit')}} <i class="fas fa-pen"/></div>
        <h3>{{$t('invoicing.titleData')}}:</h3>
        <strong>{{accountHolder.firstname}} {{accountHolder.lastname}}</strong><br/>
        <span v-if="accountHolder.company != null && accountHolder.company != ''"><strong>{{accountHolder.company}}</strong></span><br>
        {{accountHolder.street1}}<br/>
        <span v-if="accountHolder.street2 != null && accountHolder.street2 != ''">{{accountHolder.street2}}<br/></span>
        {{accountHolder.zipCode}} {{accountHolder.city}}<br/>
        {{accountHolder.country}}<br/>
        <br/>
        <span v-if="accountHolder.taxId != null">{{$t('invoicing.taxId')}}: {{accountHolder.taxId}}<br/><br/></span>
        <strong>E-Mail:</strong>&nbsp;
        {{accountHolder.email}}
          <span v-if="tenant.emailConfirmed == true" class="emailConfirmed"><i class="fas fa-check"/></span>
          <span v-else class="emailUnconfirmed"><i class="fas fa-exclamation-circle"/>&nbsp;<button @click="resendConfirmation">{{$t('invoicing.btnSendNewLink')}}</button></span>
          <br/>
        <br/>
      </div>
      <div v-else-if="tenant != null" class="tenant-info">
        <h3>{{$t('invoicing.titleData')}}</h3>
        <div class='actionButton bgBrown refresh no-float' @click="showAccountHolderModel">{{$t('invoicing.btnAddAccoutHolder')}}</div>
      </div>
      <div class="tenant-info" v-if="invoiceHistory != null && invoiceHistory.length > 0">
        <h3>{{$t('invoicing.history')}}</h3>
        <span v-for="i in invoiceHistory" :key="i._id" :class="{hasPdf: i.hasPdf}" @click="loadAndShowPDF(i)">
          {{i.invoiceNr}} <strong>[{{$t(`invoicing.invoice_status_${i.invoiceStatus}`)}}]</strong>, {{formatAmount(i.amount)}}, {{new Date(i.created).toLocaleString()}}
            <i v-if="i.hasPdf" class="far fa-file-pdf"/><br/>
        </span>
      </div>

      <div v-if="subscriptions != null && tenant.accountHolder != null && tenant.emailConfirmed && (tenant.subscriptionType != 'EXPIRED' && tenant.subscriptionType != 'FREE')" >
        <h3>{{$t('invoicing.subscriptions')}}:</h3>
        <SubscriptionsComponent v-if="tenant.accountHolder != null && tenant.emailConfirmed && (tenant.subscriptionType != 'EXPIRED'
          && tenant.subscriptionType != 'FREE') || showSubscriptions" class="tenant-info" :tenant="tenant" :subscriptionsById="subscriptionsById"
          @subscribe="createPayment"/>
      </div>
    </div>
    <CreateOrEditAccountHolderModal ref="createOrEditAccountHolderModal" @confirm="refresh" :accountHolderToEdit="accountHolderToEdit" :createAccountHolder="accountHolder == null" :tenant="tenant"/>
    <ConfirmModal ref="confirmCancelModal" :title="$t('invoicing.titleCancelSubscription')" :text="$t('invoicing.textCancelSubscription')"/>
    <ConfirmSubscriptionModal v-if="tenant != null" ref="confirmSubscriptionModal" :tenant="tenant" :subscriptionsById="subscriptionsById"/>
  </div>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';
import CreateOrEditAccountHolderModal from '@/components/modals/CreateOrEditAccountHolderModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import SubscriptionsComponent from '@/components/SubscriptionsComponent.vue';
import ConfirmSubscriptionModal from '@/components/modals/ConfirmSubscriptionModal.vue';

export default {
  name: 'InvoicingView',
  props: ['transactionId'],
  components: {
    CreateOrEditAccountHolderModal,
    ConfirmModal,
    SubscriptionsComponent,
    ConfirmSubscriptionModal,
  },
  data() {
    return {
      tenant: null,
      lastInvoice: null,
      accountHolder: null,
      accountHolderToEdit: null,
      invoiceHistory: [],
      subscriptions: null,
      subscriptionsById: null,
    };
  },
  async mounted() {
    this.refresh();
  },
  computed: {
    canReactivate() {
      return this.tenant != null && this.tenant.subscriptionExpiryDate != null && (new Date().getTime() < new Date(this.tenant.subscriptionExpiryDate).getTime());
    },
  },
  methods: {
    formatAmount(a) {
      return parseFloat(a).toLocaleString(this.$t('key'), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currency: 'EUR',
        style: 'currency',
        currencyDisplay: 'code',
      });
    },
    async refresh() {
      const res = await ServiceHelper.loadDataFromBackend(`tenant/paymentStatus/${this.transactionId}`);
      this.tenant = res.payload.tenant;
      this.lastInvoice = res.payload.invoice;
      this.decryptName();
      this.loadInvoiceHistory();
      this.loadSubscriptions();
    },
    async loadLastInvoice() {
      const res = await ServiceHelper.loadDataFromBackend('tenant/lastInvoice');
      this.lastInvoice = res.payload.invoice;
    },
    async loadInvoiceHistory() {
      const res = await ServiceHelper.loadDataFromBackend('tenant/invoices');
      this.invoiceHistory = res.payload.invoices;
    },
    async loadSubscriptions() {
      try {
        const res = await ServiceHelper.loadDataFromBackend('subscriptions');
        this.subscriptions = res.payload.subscriptions;
        this.subscriptionsById = {};
        this.subscriptions.forEach((s) => {
          this.subscriptionsById[s.id] = s;
        });
      } catch (e) {
        console.error(e);
      }
    },
    decryptName() {
      if (this.tenant != null && this.tenant.tenantName != null) {
        this.tenant.tenantName = CryptoHelper.decrypt(this.tenant.tenantName);
      }
      if (this.tenant != null && this.tenant.accountHolder != null) {
        try {
          const key = Buffer.from(this.tenant.keyForAdminInfo, 'base64');
          const dec = CryptoHelper.decrypt(this.tenant.accountHolder, key);
          this.accountHolder = JSON.parse(dec);
        } catch (e) {
          console.error(e);
        }
      }
    },
    async loadAndShowPDF(invoice) {
      if (!invoice.hasPdf) return;
      const response = await ServiceHelper.loadDataFromBackend(`tenant/invoicePdf/${invoice.transactionId}`);
      const blob = new Blob([Buffer.from(response.payload.invoice.pdfData, 'base64')], { type: 'application/pdf' });
      const objUrl = URL.createObjectURL(blob);
      window.location.href = objUrl;
      /*
            const dec = CryptoHelper.decrypt(response.payload.invoice.pdfData, key);
      const tmp = JSON.parse(dec);
      console.log(tmp);
      const blob = new Blob(Buffer.from(tmp.pdfData, 'base64'), { type: 'application/pdf' });
*/
    },
    showPDF() {
      const blob = new Blob([Buffer.from(this.lastInvoice.pdfData, 'base64')], { type: 'application/pdf' });
      const objUrl = URL.createObjectURL(blob);
      window.location.href = objUrl;
    },
    async createPayment(subscriptionType, periodType, level) {
      if (this.tenant == null || this.tenant.accountHolder == null || !this.tenant.emailConfirmed) {
        alert(this.$t('invoicing.complete_accountholder'));
        return;
      }
      console.log('createPayment', subscriptionType, periodType, level);

      if (await this.$refs.confirmSubscriptionModal.show(subscriptionType, periodType, level)) {
        const response = await ServiceHelper.loadDataFromBackend(`tenant/createPayment/${subscriptionType}`);
        try {
          if (response.status === 200) {
            // ok
            this.refresh();
            // if (response.payload.payment != null && response.payload.payment.status !== 'paid') {
            // document.location.href = response.payload.payment._links.checkout.href;
            // window.open(response.payload.payment._links.checkout.href);
            // console.log(response.payload.payment._links.checkout.href);
            // this.showCheckoutIFrame = true;
          } else {
            this.refresh();
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
    showAccountHolderModel() {
      this.accountHolderToEdit = JSON.parse(JSON.stringify(this.accountHolder));
      this.$refs.createOrEditAccountHolderModal.show();
    },
    async resendConfirmation() {
      const res = await ServiceHelper.loadDataFromBackend('tenant/resendConfirmation');
      if (res.payload.tenant != null) {
        window.alert(this.$t('invoicing.infoMailSent'));
      }
    },
    async cancelSubscription() {
      if (await this.$refs.confirmCancelModal.show()) {
        try {
          const response = await ServiceHelper.sendDataToBackend('tenant/cancelSubscription', { });
          if (response.status.code !== 0) {
            window.alert(this.$t('error.general'));
          } else {
            this.refresh();
          }
        } catch (e) {
          window.alert(this.$t('error.general'));
        }
      }
    },
    async reactivateSubscription() {
      try {
        const response = await ServiceHelper.sendDataToBackend('tenant/reactivateSubscription', { });
        if (response.status.code !== 0) {
          window.alert(this.$t('error.general'));
        } else {
          this.refresh();
        }
      } catch (e) {
        window.alert(this.$t('error.general'));
      }
    },

  },
};
</script>

<style scoped>
@import url('../styles/list.module.css');

.invoicing {
  text-align: left;
  display: flex;
}

.invoicing h3 {
  margin: 8px 0px;
}

.invoicing .tenant-info .actionButton {
  float: right;
  text-align: right;
  margin-top: -11px;
  font-size: 14px;
  margin-right: -11px;
}

.invoicing .action-button:hover {
  cursor: pointer;
  color: var(--color_orange);
}

.tenant-info {
  width: calc(100% - 40px);
  border: 1px solid var(--color_orange);
  box-shadow: 1px 1px 8px var(--color_orange);
  margin-bottom: 32px;
  padding: 16px;
}

.page {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.page .page-title {
  flex: 0 1 auto;
}

.page.actionBar {
  float: right;
}

.page .topNav {
  flex: 0 1 auto;
}

.page .page-content {
  flex: 1 1 auto;
  overflow-y: auto;
}

.emailConfirmed {
  color: var(--color_mint);
}

.emailUnconfirmed {
  color: var(--color_orange);
}

.subscription-status .running {
  color: var(--color_green);
}

.subscription-status .cancelled {
  color: var(--color_red);
}

.no-float {
  float: none !important;
  margin: 8px 0px 0px 0px !important;
}

.tenant-info.email-hint {
  background-color: var(--color_orange_half);
  color: var(--color_brown);
  font-weight: 800;
}

.hasPdf:hover {
  cursor: pointer;
  color: var(--color_orange);
}
</style>
