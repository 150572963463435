<template>
  <div class="subscriptions" v-if="tenant != null && subscriptionsById != null">
    <div class="subscriptions-wrapper">
      <SingleSubscriptionComponent :tenant="tenant" :level="1" :lang="$t('keyShort')"
        :subscriptions="{MONTHLY: subscriptionsById.MONTHLY_1, YEARLY: subscriptionsById.YEARLY_1}" @subscribe="subscribe"/>
      <SingleSubscriptionComponent :tenant="tenant" :level="2" :lang="$t('keyShort')"
        :subscriptions="{MONTHLY: subscriptionsById.MONTHLY_2, YEARLY: subscriptionsById.YEARLY_2}" @subscribe="subscribe"/>
      <SingleSubscriptionComponent :tenant="tenant" :level="3" :lang="$t('keyShort')"
        :subscriptions="{MONTHLY: subscriptionsById.MONTHLY_3, YEARLY: subscriptionsById.YEARLY_3}" @subscribe="subscribe"/>
    </div>
    <div class="net_prices_hint">{{$t('invoicing.hint_net_prices')}}</div>
  </div>
</template>
<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import SingleSubscriptionComponent from '@/components/SingleSubscriptionComponent.vue';

export default {
  name: 'SubscriptionsComponent',
  components: {
    SingleSubscriptionComponent,
  },
  props: ['tenant', 'subscriptionsById'],
  emits: ['subscribe'],
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    subscribe(type, periodType, level) {
      console.log('subscribe', type, periodType, level);
      this.$emit('subscribe', type, periodType, level);
    },
  },
};
</script>
<style scoped>

.subscriptions {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  align-items: center;
}

.subscriptions .title {
  width: 100%;
  text-align: left;
}

.subscriptions-wrapper {
  display: flex;
  flex: 1 1 auto ;
  flex-direction: row;
  column-gap: 32px;
  padding: 16px;
}

@media (max-width: 900px) {
  .subscriptions-wrapper {
    flex-direction: column;
    row-gap: 32px;
    width: 95%;
  }

  .subscription-box, ::v-deep(.subscription-box)  {
    max-width: 100%;
    width: calc(100% - 32px);
  }
}
</style>
