<template>
  <div>
    <div class="toolbar">
      <!-- convert template to config -->
      <div class="action" id="back" @click="$emit('back')">
        <i class="fas fa-arrow-left"></i><span class="actionLabel">{{ $t('general.back') }}</span>
      </div>
      <div class="action" id="execute" @click="$emit('execute')">
        <i class="fas fa-play"></i><span class="actionLabel">{{ $t('workflow.execute') }}</span>
      </div>
      <div class="action" id="save" @click="$emit('save')">
        <i class="fas fa-cloud-upload-alt"></i><span class="actionLabel">{{ $t('workflow.save') }}</span>
      </div>
      <div class="action" id="load" @click="$emit('load')">
        <i class="fas fa-cloud-download-alt"></i><span class="actionLabel">{{ $t('workflow.load') }}</span>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
// import HelpOverlay from './HelpOverlay.vue';

export default {
  name: 'WorkflowToolbarComponent',
  components: {
    // HelpOverlay,
  },
  props: [],
  data() {
    return {
    };
  },
  created() {
  },
  watch: {
  },
  methods: {
  },
};
</script>
<style scoped>
.toolbar {
  text-align: left;
  margin: 0px 0px;
  background-color: var(--color_green);
  color: white;
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  padding: 14px 10px;
  min-width: 500px;
  overflow-x: auto;
  height: 38px;
}

.toolbar .action {
  cursor: pointer;
  font-size: 18px;
  height: 32px;
  line-height: 32px;
  padding: 2px 15px 0px;
  border-radius: 8px;
  text-align: center;
}

.toolbar .action:hover {
  background: white;
  color: var(--color_green);
  box-shadow: 1px 1px 2px rgba(0,0,0, 0.3);
}

.addNewFieldChooser {
  position: absolute;
  border-radius: 0px;
  border: 1px solid white;
  background-color: #3ba67aee;
  overflow-y: auto;
  box-shadow: 2px 1px 20px #888;
  z-index: 99;
  min-width: 200px;
  margin-top: 8px;
  color: white;
  font-weight: 400;
  overflow: visible;
}

.addNewFieldChooser .fieldType {
  padding: 8px 20px;
  border-bottom: 1px solid white;
  cursor: pointer;
  width: calc(100% - 40px);
  position: relative;
  height: 22px;
}

.addNewFieldChooser .fieldType[disabled] {
  color: grey !important;
}

.addNewFieldChooser .fieldType.cancel {
  color: var(--color_almost_black);
  font-weight: 500;
}

.addNewFieldChooser .fieldType:not([disabled]):hover {
  font-weight: 700;
}

.action .actionLabel {
  font-size: 16px;
  margin-left: 8px;
}

@media screen and (max-width: 1300px) {
  .action {
    padding: 2px 8px 0px;
  }
  .action .actionLabel {
    display: none;
  }
}

</style>
