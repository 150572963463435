<template>
  <CustomModal v-model="showAccountHolderModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>
      <span v-if="!readOnly">{{createAccountHolder ? $t('invoicing.modalTitleAddAccountHolder') : $t('invoicing.modalTitleEditAccountHolder')}}</span>
      <span v-else>{{$t('modal.titleContact')}}</span>
    </template>
    <div class="form accountHolder" :style="{height: `${dialogHeight}px`}">
      <div class="row" >
        <div class="label"><div class="icon"><i class='fas fa-user-circle'/></div> {{ $t('modal.labelFirstname') }}:<sup>*</sup></div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" :disabled="readOnly" v-model="accountHolder.firstname" type="text" data-cy="accountHolder-firstname"/>
          <br/><div v-if="errorFirstname != null" class="fieldError">{{errorFirstname}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='far fa-user-circle'/></div> {{ $t('modal.labelLastname') }}:<sup>*</sup></div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" :disabled="readOnly" v-model="accountHolder.lastname" type="text" data-cy="accountHolder-lastname"/>
          <br/><div v-if="errorLastname != null" class="fieldError">{{errorLastname}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='far fa-envelope'/></div> {{ $t('modal.labelEmail') }}:<sup>*</sup></div>
        <div class="value flex"><input @keyup.enter="confirm" @blur="checkForm" :disabled="!createAccountHolder" v-model="accountHolder.email" type="text" data-cy="accountHolder-email"/>
        <span class="action" v-if="!createAccountHolder && tenant != null" @click="showEmailUpdate = true"><i class="fas fa-pen"/></span>
        <br/><div v-if="errorEmail != null" class="fieldError">{{errorEmail}}</div>
        </div>
      </div>
      <div class="row" v-if="showEmailUpdate" >
        <div class="label"></div>
        <div class="value"><input v-model="newEmail" type="text" :placeholder="$t('modal.labelEmailNew')" data-cy="accountHolder-email"/>
          <br/><div v-if="errorNewEmail != null" class="fieldError">{{errorNewEmail}}</div>
          <span><button @click="sendEmailUpdateRequest();">{{$t('invoicing.btnRequestEmailUpdate')}}</button></span>
          <span><button @click="newEmail = ''; showEmailUpdate = false">{{$t('invoicing.btnCancelEmailUpdate')}}</button></span>
        </div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-industry'/></div> {{ $t('modal.labelCompany') }}:</div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" :disabled="readOnly" v-model="accountHolder.company" type="text" data-cy="accountHolder-company"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-road'/></div> {{ $t('modal.labelStreet') }}:<sup>*</sup></div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" :disabled="readOnly" v-model="accountHolder.street1" type="text" data-cy="accountHolder-street"/>
          <br/><div v-if="errorStreet != null" class="fieldError">{{errorStreet}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-road'/></div> {{ $t('modal.labelStreet_2') }}:</div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" :disabled="readOnly" v-model="accountHolder.street2" type="text" data-cy="accountHolder-street2"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-globe'/></div> {{ $t('modal.labelZipCode') }}:<sup>*</sup></div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" :disabled="readOnly" v-model="accountHolder.zipCode" type="text" data-cy="accountHolder-zip"/>
          <br/><div v-if="errorZipCode != null" class="fieldError">{{errorZipCode}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-city'/></div> {{ $t('modal.labelCity') }}:<sup>*</sup></div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" :disabled="readOnly" v-model="accountHolder.city" type="text" data-cy="accountHolder-city"/>
          <br/><div v-if="errorCity != null" class="fieldError">{{errorCity}}</div>
        </div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-globe-europe'/></div> {{ $t('modal.labelCountry') }}:</div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" :disabled="true" type="text" data-cy="accountHolder-country" value="Deutschland"/></div>
      </div>
      <div class="row">
        <div class="label"><div class="icon"><i class='fas fa-money-bill'/></div> {{ $t('modal.taxId') }}:</div>
        <div class="value"><input @keyup.enter="confirm" @blur="checkForm" :disabled="readOnly" v-model="accountHolder.taxId" type="text" data-cy="accountHolder-taxId"/></div>
      </div>

    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';
import ServiceHelper from '@/helpers/ServiceHelper';
import CryptoHelper from '@/helpers/CryptoHelper';

const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  name: 'CreateOrEditAccountHolderModal',
  props: ['tenant', 'accountHolderToEdit', 'createAccountHolder'],
  components: {
    CustomModal,
  },
  data() {
    return {
      showAccountHolderModal: false,
      accountHolder: {},
      dialogHeight: 200,
      errorFirstname: null,
      errorLastname: null,
      errorEmail: null,
      errorStreet: null,
      errorZipCode: null,
      errorCity: null,
      errorNewEmail: null,
      showEmailUpdate: false,
      newEmail: '',
    };
  },
  watch: {
    accountHolderToEdit(newVal) {
      this.accountHolder = newVal != null ? newVal : {};
    },
  },
  mounted() {
    this.accountHolder = this.accountHolderToEdit != null ? this.accountHolderToEdit : {};
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  methods: {
    checkForm() {
      this.errorFirstname = null;
      this.errorLastname = null;
      this.errorZipCode = null;
      this.errorCity = null;
      this.errorStreet = null;
      this.errorEmail = null;
      let hasErrors = false;

      if (this.accountHolder.email == null || this.accountHolder.email.trim() === '') {
        this.errorEmail = this.$t('validationError.emailEmpty');
        hasErrors = true;
      } else if (!emailRe.test(this.accountHolder.email.toLowerCase())) {
        this.errorEmail = this.$t('validationError.invalidEmail');
        hasErrors = true;
      }

      if (this.accountHolder.firstname == null || this.accountHolder.firstname.trim() === '') {
        this.errorFirstname = this.$t('validationError.firstNameEmpty');
        hasErrors = true;
      }

      if (this.accountHolder.lastname == null || this.accountHolder.lastname.trim() === '') {
        this.errorLastname = this.$t('validationError.lastNameEmpty');
        hasErrors = true;
      }

      if (this.accountHolder.zipCode == null || this.accountHolder.zipCode.trim() === '') {
        this.errorZipCode = this.$t('validationError.zipCodeEmpty');
        hasErrors = true;
      }

      if (this.accountHolder.city == null || this.accountHolder.city.trim() === '') {
        this.errorCity = this.$t('validationError.cityEmpty');
        hasErrors = true;
      }

      if (this.accountHolder.street1 == null || this.accountHolder.street1.trim() === '') {
        this.errorStreet = this.$t('validationError.streetEmpty');
        hasErrors = true;
      }
      return !hasErrors;
    },
    resizeHandler() {
      this.dialogHeight = window.innerHeight - 300;
    },
    async show(readOnly) {
      this.errorFirstname = null;
      this.errorLastname = null;
      this.readOnly = readOnly;
      this.showAccountHolderModal = true;
    },
    hide() {
      this.showAccountHolderModal = false;
    },
    async sendEmailUpdateRequest() {
      this.errorNewEmail = null;
      let hasErrors = false;

      if (this.newEmail == null || this.newEmail.trim() === '') {
        this.errorNewEmail = this.$t('validationError.emailEmpty');
        hasErrors = true;
      } else if (!emailRe.test(this.newEmail.toLowerCase())) {
        this.errorNewEmail = this.$t('validationError.invalidEmail');
        hasErrors = true;
      }

      if (hasErrors) return;

      const res = await ServiceHelper.sendDataToBackend('tenant/updateEmail', { newEmail: this.newEmail }, 'POST');
      if (res.status.code === 0) {
        this.showEmailUpdate = false;
        if (this.tenant.emailConfirmed) {
          this.accountHolder.newEmail = this.newEmail;
        } else {
          this.accountHolder.newEmail = null;
          this.accountHolder.email = this.newEmail;
        }
        window.alert(this.$t('invoicing.infoMailSent'));
      } else {
        this.errorNewEmail = this.$t('error.requestingNewEmailFailed');
      }
    },
    async confirm() {
      if (this.readOnly) {
        this.hide();
        return;
      }
      if (!this.checkForm()) {
        return;
      }

      // encrypt and send accountHolder
      try {
        this.accountHolder.country = 'Deutschland';

        const payload = CryptoHelper.encrypt(JSON.stringify(this.accountHolder), Buffer.from(this.tenant.keyForAdminInfo, 'base64'));
        if (this.createAccountHolder) {
          const res = await ServiceHelper.sendDataToBackend('tenant/accountHolder', { accountHolder: payload }, 'POST');
          if (res.status.code === 0) {
            this.showEmailUpdate = false;
            window.alert(this.$t('invoicing.infoMailSent'));
            this.$emit('confirm');
          } else {
            this.errorNewEmail = this.$t('error.requestingNewEmailFailed');
          }
        } else {
          const res = await ServiceHelper.sendDataToBackend('tenant/accountHolder', { accountHolder: payload }, 'PUT');
          if (res.status.code === 0) {
            this.showEmailUpdate = false;
            this.$emit('confirm');
          } else {
            this.errorNewEmail = this.$t('error.general');
          }
        }
      } catch (error) {
        console.error(error);
      }
      this.hide();
    },
    cancel() {
      this.hide();
    },
  },
};
</script>

<style scoped>
::v-deep(.modal_content .form.accountHolder) {
  max-height: 580px;
  overflow-y: auto;
}
::v-deep(.modal_content .form .row .label .icon ){
  width: 30px;
  display: inline-block;
}

::v-deep(.modal_content .form .row .label svg) {
  color: var(--color_orange);
}

::v-deep(.modal_content .form .row .label) {
  vertical-align: top;
  font-size: 16px;
}

::v-deep(.modal_content .form .row .fieldError) {
  color: var(--color_red);
  font-size: 12px;
}

::v-deep(.modal_content .form .row .value input:disabled) {
  color: black;
  opacity: 1;
  border: none;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
  padding: 0;
}

::v-deep(.modal_content .form .row .value.flex) {
  display: inline-block;
}

::v-deep(.modal_content .form .row .value.flex input) {
  width: calc(100% - 30px);
}

::v-deep(.modal_content .form .row .action:hover) {
  cursor: pointer;
}

@media (max-width: 800px) {
  ::v-deep(.modal_content .form .row .label) {
    display: block;
    width: calc(100% - 40px);
    font-size: 14px;
  }
  ::v-deep(.modal_content .form .row .value) {
    display: block;
    width: calc(100% - 40px);
    padding-left: 34px;
  }
}

</style>
