<template>
  <CustomModal v-model="showModal" @confirm="confirm" @cancel="cancel">
    <template v-slot:title>{{title}}</template>
    <div>{{text}}</div>
    <div class="taskList" v-if="taskListByReport.length > 0">
      <div class="reportWrapper" v-for="(resultForReport, idx) in taskListByReport" :key="idx">
        <div class="reportItem">
          <input class="reportFlag" type="checkbox" v-model="resultForReport.active" @click="toggleWholeReport(resultForReport, !resultForReport.active)">
          <div class="reportName" @click="resultForReport.expanded = !resultForReport.expanded">{{resultForReport.report.name}}</div>
        </div>
        <div v-if="resultForReport.expanded">
          <div class="taskItem" v-for="(t, taskIdx) in resultForReport.tasks" :key="taskIdx">
            <input class="taskFlag" type="checkbox" v-model="t.active" @click="toggleSingleTask(resultForReport, t, !t.active)">
            <div class="taskName" v-html="t.taskName"></div>
          </div>
        </div>
      </div>

    </div>
    <div class="emptyHint" v-else>
      {{$t('general.empty')}}
    </div>
  </CustomModal>
</template>

<script>
/* eslint-disable no-console,no-alert,no-underscore-dangle */

import CustomModal from '@/components/modals/CustomModal.vue';
import CryptoHelper from '@/helpers/CryptoHelper';

export default {
  name: 'ConfirmWorkflowExecutionModal',
  props: ['title', 'text'],
  components: {
    CustomModal,
  },
  data() {
    return {
      showModal: false,
      resolve: null,
      reject: null,
      promise: null,
      taskListByReport: [],
    };
  },
  methods: {
    async show(taskListByReport) {
      this.showModal = true;
      this.taskListByReport = taskListByReport;
      taskListByReport.forEach((r) => {
        const resultForReport = r;
        resultForReport.active = true;
        resultForReport.expanded = true;
        resultForReport.report.name = CryptoHelper.decrypt(resultForReport.report.name);
        resultForReport.tasks.forEach((t) => {
          const task = t;
          task.active = true;
        });
      });
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
      return this.promise;
    },
    hide() {
      this.showModal = false;
    },
    confirm() {
      this.resolve(this.taskListByReport);
      this.hide();
    },
    cancel() {
      this.resolve(null);
      this.hide();
    },
    toggleWholeReport(r, newState) {
      r.tasks.forEach((t) => {
        const task = t;
        task.active = newState;
      });
    },
    toggleSingleTask(r, t, newState) {
      const task = t;
      const rep = r;
      task.active = newState;
      rep.active = r.tasks.filter((f) => f.active).length > 0;
    },
  },
};
</script>

<style scoped>
.taskList, .emptyHint {
  max-height: 50%;
  min-height: 200px;
  overflow-y: auto;
  background: #f8f8f8;
  margin: 8px 0px;
}

.emptyHint {
  max-height: 100px;
  min-height: 0px;
  padding: 8px;
}

.taskList .taskItem, .taskList .reportItem {
  padding: 8px 8px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

.taskList .taskItem {
  padding-left: 32px;
  background-color: var(--color_list_1);
  color: var(--color_gray);
}

.taskList .reportWrapper {
  background-color: var(--color_gray);
  color: white;
  margin-top: 16px;
}

.taskList .reportWrapper:first-of-type {
  margin-top: 0px;
}

.taskList .taskItem:nth-child(even) {
  background-color: var(--color_list_2);
}

.taskList .taskItem .taskFlag, .taskList .reportItem .reportNameFlag {
  flex: 0 1 auto;
}

.taskList .taskItem .taskName, .taskList .reportItem .reportName {
  flex: 1 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.reportName {
  cursor: pointer;
}

.reportName:hover {
  text-decoration: underline;
}

</style>
